import React from "react";
import { Row, Container, DropdownButton, Dropdown, Breadcrumb } from "react-bootstrap";
import { useStaticQuery, graphql, Link } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import RecipeCard from "../components/recipeCard";
import BlogPost from "../components/blogPost";
import { cookbook_sections, recipe_data } from "../components/cookbookData";
import PrevNextButtons from "../components/prevNextButtons";

const uri = "/cookbook/";

const CookbookSection = ({
  location,
  children,
  sectionLink,
  sectionName,
  sectionTitle,
  prevName,
  prevLink,
  nextName,
  nextLink,
}) => {
  const searchQuery = location.state?.searchQuery;

  const { images } = useStaticQuery(
    graphql`
      query cookbookSectionImages {
        images: allFile(filter: { sourceInstanceName: { eq: "cookbook-images" } }) {
          edges {
            node {
              dir
              ext
              sourceInstanceName
              name
              childImageSharp {
                gatsbyImageData(width: 500)
              }
            }
          }
        }
      }
    `
  );

  var imgarr = [];
  for (var img of images.edges) {
    imgarr[img.node.name] = img.node.childImageSharp.gatsbyImageData;
  }

  const cookbook_sections_dropdown = cookbook_sections.filter((rec) => rec.name !== "Dutch Oven");

  const sideBarOptions = (
    <>
      <div id="cookbook-index-top">
        <Link to="/cookbook/">Camping Cookbook</Link>
      </div>
      {cookbook_sections.map((section) => (
        <div key={section.link} className="d-flex cookbook-index-links">
          {section.link === sectionLink ? (
            <div className="generic-menu-block-current me-1">&nbsp;&nbsp;</div>
          ) : (
            <div className="generic-menu-block me-1">&nbsp;&nbsp;</div>
          )}
          <div>
            <Link className="me-1" to={"/cookbook/" + section.link + "/"}>
              {section.menuName}
            </Link>
          </div>
        </div>
      ))}
    </>
  );

  const dropDownOptions = (
    <DropdownButton title="Cookbook Sections">
      <Dropdown.Item key={0} href="/cookbook/">
        Top
      </Dropdown.Item>
      {cookbook_sections_dropdown.map((section) => (
        <Dropdown.Item key={section.link} href={"/cookbook/" + section.link + "/"}>
          {section.name}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );

  return (
    <Layout
      pageInfo={{ pageName: "cookbook", searchQuery: searchQuery }}
      sideBarOptions={sideBarOptions}
      dropDownOptions={dropDownOptions}
      sideBarWidth="190px"
    >
      <Seo
        title="Camping Cookbook"
        keywords={[`cookbook`, `recipes`]}
        description="The best online camping cookbook!"
        imageSrc="/campfire-896196_1280.jpg"
        imageWidth="1280"
        imageHeight="854"
        imageAlt="Cooking at Camp"
        url={uri}
      />

      <Container fluid className="text-center">
        <Row className="justify-content-left my-3">
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/cookbook/">Cookbook</Breadcrumb.Item>
            <Breadcrumb.Item active>{sectionName}</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
      </Container>

      <BlogPost
        title={sectionTitle + " Camping Recipes"}
        adCount={0}
        header={
          <PrevNextButtons
            prevLink={prevLink}
            prevName={prevName === undefined ? "Previous" : prevName}
            nextLink={nextLink}
            nextName={nextName === undefined ? "Next" : nextName}
          />
        }
      >
        <Container fluid className="text-center justify-content-center">
          {children}

          <Row key={0} className="ps-5 pe-5 pb-2">
            <h3 className="cookbook-section-title">
              <span className="cookbook-section-title-text">RECIPES</span>
            </h3>
          </Row>

          {recipe_data.map((section) => (
            <Row key={section.section} className="justify-content-center flex">
              {section.section !== sectionName ? (
                <></>
              ) : (
                section.recipes.map((recipe) => (
                  <RecipeCard
                    key={recipe.uri}
                    title={recipe.title}
                    uri={recipe.uri}
                    image={imgarr[recipe.image]}
                    excerpt={recipe.excerpt}
                    className="cookbook-card"
                    bodyClassName="cookbook-card-body"
                  />
                ))
              )}
            </Row>
          ))}
        </Container>
        <div className="pb-4 pt-4">
          <PrevNextButtons
            prevLink={prevLink}
            prevName={prevName === undefined ? "Previous" : prevName}
            nextLink={nextLink}
            nextName={nextName === undefined ? "Next" : nextName}
          />
        </div>
      </BlogPost>
    </Layout>
  );
};

export default CookbookSection;
