import React from "react";
import { Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import CookbookSection from "../../components/cookbookSection";

const BeveragesCookbookSection = ({ location }) => {
  return (
    <CookbookSection
      location={location}
      sectionLink="beverages"
      sectionName="Beverages"
      sectionTitle="Beverage"
      prevLink="/cookbook/"
      nextLink="/cookbook/breakfasts/"
    >
      <Row className="d-flex pb-4 pt-4 flex-wrap justify-content-center" id="cookbook-top-text">
        <div className="ps-4 pe-4 pb-4 cookbook-top-section d-flex flex-column">
          <div>The right beverages can make your camping experience perfect</div>
          <div className="pt-2">
            <StaticImage width={300} src="../../images/cookbook/coffee-mugs.jpg" alt="Coffee cups" />
          </div>
        </div>
        <div className="ps-4 pe-4 pb-4 cookbook-top-section d-flex flex-column">
          <div className="flex-fill">... or at least a little better</div>
          <div className="pt-2">
            <StaticImage width={300} src="../../images/cookbook/glowing-tents.jpg" alt="Tent in the night" />
          </div>
        </div>
      </Row>
    </CookbookSection>
  );
};

export default BeveragesCookbookSection;
